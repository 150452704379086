import { useState, MouseEventHandler, useCallback } from "react";
import { Link, Text } from "@biblioteksentralen/js-utils";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { useTranslation } from "../../../utils/hooks/useTranslation";

export const HeaderUserInfo = () => {
  const { t } = useTranslation();
  const { user, rediaPlatform } = useRediaPlatformContext();
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const onLogoutClick: MouseEventHandler = useCallback(
    async (event) => {
      event.preventDefault();
      if (!rediaPlatform) return;
      await rediaPlatform.logout();
      setIsLoggedOut(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setIsLoggedOut(false);
    },
    [rediaPlatform]
  );

  return (
    <Text>
      {isLoggedOut ? (
        <>{t("Takk for besøket 👋")}</>
      ) : user ? (
        <>
          {user.email}{" "}
          <Link as="button" onClick={onLogoutClick}>
            {t("Logg ut")}
          </Link>
        </>
      ) : null}
    </Text>
  );
};
