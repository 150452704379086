import { Session, SessionToken } from "./interfaces";
import { KeyValueStore } from "./sessionStore";

type FetchAPI = (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;

export const createSessionMiddleware = (
  sessionStore: KeyValueStore<Session>,
  { onSessionExpired }: { onSessionExpired?: () => any }
) => {
  const withToken = (init: RequestInit, { token }: SessionToken): RequestInit => {
    const headers = new Headers(init.headers ?? {});
    headers.set("Authorization", `Bearer ${token}`);
    return {
      ...init,
      headers,
    };
  };

  const middleware =
    (next: FetchAPI) =>
    async (input: URL | RequestInfo, init: RequestInit = {}) => {
      const session = sessionStore.get();
      const response = await (session?.token ? next(input, withToken(init, session.token)) : next(input, init));
      if (response.status === 401) {
        console.log(`⏰ User session expired, clearing session`);
        sessionStore.clear();
        if (onSessionExpired) onSessionExpired();
      }
      return response;
    };

  return middleware;
};
