import { Box, ExternalLink, Flex, Link } from "@biblioteksentralen/js-utils";
import { SanityKeyed } from "@libry-content/types";
import { getPath } from "../../../utils/getPath";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLink from "../../InternalLink";
import { FooterMenuItem, ResolvedFooterMenu } from "../sanityQuery";
import { FooterMenuList } from "./FooterMenuList";

export const menuItemHasContent = (menuItem?: FooterMenuItem) =>
  typeof menuItem?._type === "string" && (menuItem?._type !== "externalLink" || menuItem.url);

const getKey = (item: FooterMenuItem): string => ("_key" in item ? item._key : item._id);

export const FooterMenuItems = (props: { footerMenu: SanityKeyed<ResolvedFooterMenu> }) => {
  const { ts } = useTranslation();
  return (
    <FooterMenuList>
      {props.footerMenu.menuItems.filter(menuItemHasContent).map((menuItem: FooterMenuItem) => (
        <Box as="li" key={getKey(menuItem)} whiteSpace="normal">
          {menuItem._type === "externalLink" && (
            <Flex alignItems="center" display="inline-flex" gap=".3rem">
              <Link href={menuItem.url!} variant="plain">
                {ts(menuItem?.title)}
              </Link>
              <ExternalLink width="0.75em" />
            </Flex>
          )}
          {menuItem._type === "staticPage" && (
            <InternalLink href={getPath(menuItem)} variant="plain">
              {ts(menuItem?.title)}
            </InternalLink>
          )}
        </Box>
      ))}
    </FooterMenuList>
  );
};
