import { components as patronComponents } from "../types/patron";
import { components as configurationComponents } from "../types/configuration";

type BaseError = { info: string; code: number };
type PatronApiError = patronComponents["schemas"]["Error"];
type ConfigurationApiError = configurationComponents["schemas"]["Error"];

export class RediaPlatformError<ErrorType extends BaseError> extends Error {
  errors: ErrorType[];
  code: ErrorType["code"] | undefined;
  info: ErrorType["info"] | undefined;
  constructor(error: { errors: ErrorType[] }) {
    const errors = error.errors ?? [];
    super(errors.map(({ code, info }) => `[${code}] ${info}`).join(" "));
    this.errors = errors;
    this.code = errors?.[0]?.code;
    this.info = errors?.[0]?.info;
  }
}

export class RediaPlatformConfigurationApiError extends RediaPlatformError<ConfigurationApiError> {}

export class RediaPlatformPatronApiError extends RediaPlatformError<PatronApiError> {}

export class LoginFailed extends Error {
  public cause: RediaPlatformConfigurationApiError | RediaPlatformPatronApiError;
  public code: "invalid_redia_platform_customer_id" | "invalid_credentials" | "unknown_error";
  constructor(error: RediaPlatformConfigurationApiError | RediaPlatformPatronApiError) {
    super(`Failed to login: ${error.message}`);
    this.cause = error;
    // Map the most common error causes to local error codes, to make it easier to consume the error.
    if (error instanceof RediaPlatformConfigurationApiError && error.code === 150) {
      this.code = "invalid_redia_platform_customer_id";
    } else if (error instanceof RediaPlatformPatronApiError && error.code === 1001) {
      this.code = "invalid_credentials";
    } else {
      this.code = "unknown_error";
    }
  }
}
