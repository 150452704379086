import { ReactNode, createContext, useContext } from "react";
import { useRediaPlatform } from "./useRediaPlatform";
import { RediaPlatform, Patron } from "@libry-content/redia-platform";

export interface RediaPlatformContext {
  isRediaPlatformConfigured: boolean;
  isSessionReady: boolean;
  rediaPlatform: RediaPlatform | undefined;
  user: Patron | undefined;
}

const initialState: RediaPlatformContext = {
  rediaPlatform: undefined,
  user: undefined,
  isSessionReady: false,
  isRediaPlatformConfigured: false,
};

const Context = createContext(initialState);

export const useRediaPlatformContext = () => useContext(Context);

export function RediaPlatformProvider({ children }: { children: ReactNode }) {
  const rediaPlatform = useRediaPlatform();

  return <Context.Provider value={rediaPlatform}>{children}</Context.Provider>;
}
