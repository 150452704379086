import React, { useState } from "react";
import { LibrarySystem } from "@libry-content/types";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { BiSearch, colors } from "@biblioteksentralen/js-utils";
import { logEvent } from "../../utils/analytics/Plausible";
import { useTranslation } from "../../utils/hooks/useTranslation";

interface Props {
  librarySystem?: LibrarySystem;
  id: string;
}

export const handleLegacyCatalogSearch = (query: string) => {
  window.location.assign(`/api/catalog-search?term=${encodeURIComponent(query)}`);
};

const LegacyCatalogSearch = (props: Props) => {
  const { librarySystem, id } = props;
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  if (!librarySystem) {
    return null;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    logEvent("Katalogsøk");
    handleLegacyCatalogSearch(inputValue);
  };

  return (
    <form role="search" id={id} onSubmit={onSubmit}>
      <InputGroup size="sm">
        <Input
          type="search"
          aria-label={t("Søk i samlingen")}
          placeholder={t("Søk i samlingen")}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          borderRadius={5}
          background={colors.grey05}
          _hover={{ backgroundColor: colors.grey15 }}
          _placeholder={{ color: colors.grey90 }}
          minWidth="8rem"
        />
        <InputRightElement as="button" type="submit" onClick={onSubmit} title={t("Søk")}>
          <BiSearch />
        </InputRightElement>
      </InputGroup>
    </form>
  );
};

export default LegacyCatalogSearch;
