import { colors, Stack } from "@biblioteksentralen/js-utils";
import { Box, Flex, Grid, Text } from "@chakra-ui/layout";
import { ResolvedSite } from "../../../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import SocialMediaView from "../../contactInfo/SocialMedia";
import { HeaderFooterContainer } from "../../ContentContainer";
import { useCommonData } from "../CommonDataProvider";
import Coatofarms from "./CoatOfArms";
import { FooterMenu } from "./FooterMenu";
import LibryContentLinks from "./LibryContentLinks";

export function Footer() {
  const { site } = useCommonData();
  const palette = useSitePalette();

  return (
    <Grid as="footer" gridTemplateRows="1fr auto auto" minHeight="20rem">
      <Box {...palette.colors.footer.css}>
        <HeaderFooterContainer>
          <Stack spacing="3rem" padding="2.5rem 0 2rem">
            <FooterMenu />
            <Coatofarms alignSelf="flex-end" padding=".75rem 0 .75rem .75rem" />
          </Stack>
        </HeaderFooterContainer>
      </Box>
      <Box background={colors.grey30}>
        <HeaderFooterContainer>
          <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap" padding="1rem 0" gap="1rem">
            <Editor site={site} />
            <SocialMediaView socialMedia={site?.socialMedia} gap="1rem" marginLeft="auto" />
          </Flex>
        </HeaderFooterContainer>
      </Box>
      <LibryContentLinks />
    </Grid>
  );
}

const Editor = (props: { site?: ResolvedSite }) => {
  const { t } = useTranslation();

  if (!props.site?.editorName) return null;

  return (
    <Box>
      {t("Ansvarlig redaktør")}
      {": "}
      <Text as="span" translate="no">
        {props.site.editorName}
      </Text>
    </Box>
  );
};
