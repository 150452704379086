import { useEffect, useMemo, useState } from "react";
import { Patron, RediaPlatform, createRediaPlatformClient } from "@libry-content/redia-platform";
import { useClientSideReady } from "../utils/hooks/useClientSideReady";
import { useCommonData } from "../components/layout/CommonDataProvider";
import { isJestTest } from "@libry-content/common";
import { z } from "zod";

export interface RediaPlatformContext {
  isRediaPlatformConfigured: boolean;
  isSessionReady: boolean;
  rediaPlatform: RediaPlatform | undefined;
  user: Patron | undefined;
}

const rediaPlatformConfigSchema = z.object({
  NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT: z.enum(["dev", "staging", "prod"]),
  NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID: z.string(),
});

const rediaPlatformConfig = rediaPlatformConfigSchema.safeParse({
  NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT: process.env.NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT,
  NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID: process.env.NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID,
});

if (!rediaPlatformConfig.success && !isJestTest) {
  console.warn(`Redia Platform integration not enabled`, rediaPlatformConfig.error);
}

const localStorageSessionKey = "libry-content-session";

export const useRediaPlatform = () => {
  const customerId = useCommonData()?.site?.librarySystem?.rediaCustomerId;
  const clientSideReady = useClientSideReady();
  const [user, setUser] = useState<Patron | undefined>(undefined);
  const [isSessionReady, setIsSessionReady] = useState<boolean>(false);
  const isRediaPlatformConfigured = rediaPlatformConfig.success && typeof customerId !== "undefined";

  const rediaPlatform = useMemo(() => {
    if (!clientSideReady || !rediaPlatformConfig.success || typeof customerId === "undefined") return undefined;
    return createRediaPlatformClient({
      environment: rediaPlatformConfig.data.NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT,
      clientId: rediaPlatformConfig.data.NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID,
      customerId,
      onSessionChange: (session) => {
        // Oppdaterer React state når bruker har logget inn/ut
        console.debug("User state changed", session?.user);
        setUser(session?.user);
        setIsSessionReady(true);
      },
      onSessionExpired: () => {
        // Kanskje vi burde vise en form for toast her? Eller noe annet?
        // Vi trenger ikke kalle setUser her fordi onSessionChange også kalles.
      },
    });
  }, [clientSideReady, customerId]);

  useEffect(() => {
    const onStorageEvent = (event: StorageEvent) => {
      if (event.key === localStorageSessionKey) {
        // Oppdaterer React state når bruker har logget inn/ut i annet vindu/annen fane.
        setUser(rediaPlatform?.getUser());
      }
    };
    window.addEventListener("storage", onStorageEvent);
    return () => {
      window.removeEventListener("storage", onStorageEvent);
    };
  }, [rediaPlatform]);

  return {
    isRediaPlatformConfigured,
    rediaPlatform,
    user,
    isSessionReady,
  };
};
