import { Box, ConditionalWrapper, Flex, HStack, Image, Text, VisuallyHidden } from "@biblioteksentralen/js-utils";
import { imageUrlBuilder } from "@libry-content/common";
import NextLink from "next/link";
import { useCurrentCanonicalPath } from "../../../utils/hooks/useCurrentCanonicalPath";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { HideWithCSS } from "../../HideWithCSS";
import { useCommonData } from "../CommonDataProvider";
import { headerLinkDecorationStyle } from "../utils";
import { getSiteNameparts } from "./getSiteNameParts";

function Logo() {
  const { t } = useTranslation();
  const isOnFrontPage = useCurrentCanonicalPath() === "";

  return (
    <ConditionalWrapper wrapper={(props) => <h1 {...props} />} condition={isOnFrontPage}>
      <NextLink href="/">
        {!isOnFrontPage && <VisuallyHidden>{t("Forsiden")}</VisuallyHidden>}
        <Flex role="group" translate="no" flexShrink={0}>
          <HideWithCSS below="lg" width="100%">
            <DesktopLogo />
          </HideWithCSS>
          <HideWithCSS above="lg">
            <MobileLogo />
          </HideWithCSS>
        </Flex>
      </NextLink>
    </ConditionalWrapper>
  );
}

const DesktopLogo = () => {
  const { site } = useCommonData();
  const showLogoText = !site?.logoContainsName || !site?.logo?.asset;

  return (
    <HStack spacing="1rem" justifyContent="space-between">
      <HStack spacing="1rem">
        {site?.logo?.asset && (
          <Box display={{ base: "none", sm: "block" }}>
            <Image height="2.75rem" src={imageUrlBuilder(site?.logo)?.height(75).url() ?? ""} alt="" />
          </Box>
        )}
        <ConditionalWrapper wrapper={VisuallyHidden} condition={!showLogoText}>
          <Name />
        </ConditionalWrapper>
      </HStack>
    </HStack>
  );
};

const MobileLogo = () => <Name />;

const Name = () => {
  const { ts } = useTranslation();
  const { site } = useCommonData();

  const { boldPart, notBoldPart } = getSiteNameparts(ts, site);
  // Dersom man har lagt inn biblioteksnavnet med mellomrom i editor må mellomrommet tas vare på. Pga at logo skal wrappe over flere linjer på små skjermer bruker vi her inline-flex, men den fjerner samtidig mellomrom. Må derfor legge til gap for å ta vare på mellomrom.
  // Eks skal beholde mellomrom "Holmestrand bibliotek"
  // Eks skal ikke ha mellomrom: "Sandefjordbibliotekene"
  const gapBetweenParts = boldPart?.endsWith(" ");

  return (
    <Text
      textTransform="uppercase"
      _groupHover={{ textDecorationLine: "underline" }}
      lineHeight="1"
      transform="translateY(.1em)"
      fontSize={{ base: "md", sm: "lg", lg: "xl" }}
      css={headerLinkDecorationStyle()}
      display="inline-flex"
      flexWrap="wrap"
      gap={gapBetweenParts ? "0 0.25rem" : undefined}
    >
      <Box as="span" fontWeight={600}>
        {boldPart}
      </Box>
      {notBoldPart && <Box as="span">{notBoldPart}</Box>}
    </Text>
  );
};

export default Logo;
