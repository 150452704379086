import { Box } from "@biblioteksentralen/js-utils";
import { PropsWithChildren, useRef } from "react";
import { RediaPlatformProvider } from "../../rediaPlatform//RediaPlatformProvider";
import { CommonData } from "../../utils/fetchWithCommonDataAndCurrentSiteFilter";
import { useTranslation } from "../../utils/hooks/useTranslation";
import SkipNavButton from "../SkipNavButton";
import { CommonDataProvider } from "./CommonDataProvider";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import { LayoutGrid } from "./LayoutGrid";

interface Props {
  commonData: CommonData;
  withoutDefaultLayout?: boolean;
}

function CommonPageWrapper(props: PropsWithChildren<Props>) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <CommonDataProvider commonData={props.commonData}>
      <RediaPlatformProvider>
        <LayoutGrid>
          <SkipNavButton title={t("Hopp til hovedinnhold")} focusOnClick={() => ref.current?.focus()} />
          <Header />
          <Box
            id="main"
            as="main"
            maxWidth="100vw"
            tabIndex={-1}
            ref={ref}
            margin={props.withoutDefaultLayout ? undefined : "1rem 0 6rem"}
          >
            {props.children}
          </Box>
          <Footer />
        </LayoutGrid>
      </RediaPlatformProvider>
    </CommonDataProvider>
  );
}

export default CommonPageWrapper;
